
.kiwi-customserver-start {
    font-size: 1.1em;
    cursor: pointer;
}

.kiwi-customserver-form {
    width: 70%;
    padding: 20px;
}

@media (max-width: 1025px) {
    .kiwi-customserver-form {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .kiwi-customserver-form {
        background: var(--brand-default-bg);
        border-radius: 5px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }
}

@media (max-width: 600px) {
    .kiwi-customserver-form {
        max-width: 350px;
    }
}

.kiwi-customserver .u-input-text,
.kiwi-customserver .kiwi-customserver-have-password,
.kiwi-customserver-terms {
    margin-bottom: 1em;
}

.kiwi-customserver-form .u-submit {
    width: 100%;
    height: 50px;
    padding: 0;
    letter-spacing: 1px;
    margin: 1em 0 2em 0;
    transition: all 0.2s;
    border: none;
    font-size: 1.3em;
    line-height: 36px;
    cursor: pointer;
}

.kiwi-customserver-form .u-submit[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
}

.kiwi-customserver h2 {
    margin: 0 0 40px 0;
    padding: 0;
    cursor: default;
    font-weight: 600;
    font-size: 2.2em;
    text-align: center;
    line-height: 1.2em;
}

.kiwi-customserver-error {
    text-align: center;
    margin: 1em 0;
    padding: 1em;
}

.kiwi-customserver-error span {
    display: block;
    font-style: italic;
    margin-bottom: 8px;
}

.kiwi-customserver-error span:last-of-type {
    margin-bottom: 0;
}

.kiwi-customserver-tabs .u-tabbed-view-tabs {
    display: flex;
    padding-top: 0;
    margin-bottom: 1em;

    .u-tabbed-view-tab {
        flex-grow: 1;
        text-align: center;
    }
}


.kiwi-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

/* When the sidebar is open we will put a shadow over the text area */
.kiwi-header {
    z-index: 2;

    /* IE 11 breaks when using the shorthand flex syntax here */
    flex-grow: 0;
    flex-shrink: 1;
}

.kiwi-sidebar {
    position: absolute;
    right: -443px;
    top: 45px; /* Push the top over the top page border */
    bottom: 0;
    width: 443px;
    max-width: 443px;
    z-index: 3;
    transition: right 0.2s, width 0.2s;
    flex: 1;
}

.kiwi-container--sidebar-drawn .kiwi-sidebar {
    right: 0;
}

.kiwi-container--sidebar-open .kiwi-sidebar {
    right: 0;
    top: 0;
    flex: 1;
    position: relative;
    border-left-width: 1px;
    border-left-style: solid;
    max-width: 430px;
    z-index: 1;
    transition: right 0.2s, top 0s;
}

.kiwi-container-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.kiwi-messagelist {
    flex: 1;
}

.kiwi-serverview {
    flex: 1;
}

.kiwi-container--no-sidebar .kiwi-header,
.kiwi-container--no-sidebar .kiwi-messagelist {
    margin-right: 0;
}

.kiwi-container-toggledraw-statebrowser,
.kiwi-container-toggledraw-sidebar {
    display: none;
    width: 50px;
    position: absolute;
    top: 0;
    height: 45px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    font-size: 1.6em;
    line-height: 50px;
    transition: left 2s;
    transition-delay: 0.5s;
}

.kiwi-container-toggledraw-statebrowser {
    left: 0;
}

.kiwi-container-toggledraw-sidebar {
    right: 0;
}

.kiwi-container-toggledraw-sidebar--disabled {
    cursor: default;
}

.kiwi-container-toggledraw-statebrowser-messagecount {
    position: absolute;
    font-size: 0.6em;
    border-radius: 3px;
    line-height: 2em;
    box-sizing: border-box;
    top: 10px;
    z-index: 3;
    white-space: nowrap;
    left: 14px;
    width: 37px;
    padding: 0;
    transition: all 0.4s, z-index 0s;
    transition-delay: 0.1s;
}

.kiwi-container-toggledraw-statebrowser-messagecount::after {
    left: -15px;
    top: 20%;
    border: 0.6em solid transparent;
    border-right-color: #ddd;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.kiwi-container-statebrowser-messagecount-close {
    display: none;
}

/* When the Statebrowser is visible, apply new styles to the messagecount */
.kiwi-wrap--statebrowser-drawopen .kiwi-container-toggledraw-statebrowser-messagecount {
    left: -19px;
    z-index: 100;
}

.kiwi-wrap--statebrowser-drawopen .kiwi-container-toggledraw-statebrowser-messagecount::after {
    right: -15px;
    left: auto;
}

@keyframes kiwi-wiggle {
    0% { margin-left: 5px; }
    50% { margin-left: 0; }
    100% { margin-left: 5px; }
}

.kiwi-container-toggledraw-statebrowser-messagecount--highlight {
    animation: kiwi-wiggle 0.25s 4;
    animation-timing-function: ease-in, linear, ease-out;
}

.kiwi-container-toggledraw-statebrowser-messagecount--highlight:hover {
    animation: none;
}

.kiwi-container-empty {
    text-align: center;
    padding: 1em;
}

.kiwi-container-empty .u-button {
    border-radius: 3px;
    font-weight: 500;
    line-height: 50px;
    padding: 0 14px;
}

.kiwi-wrap .kiwi-container::after {
    content: '';
    position: absolute;
    left: auto;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    opacity: 0;
    z-index: 99;
    width: 0%;
    right: -100%;
    transition: opacity 0.1s;
}

@media screen and (max-width: 1500px) {
    .kiwi-container--sidebar-open .kiwi-sidebar {
        max-width: 350px;
    }
}

@media screen and (max-width: 769px) {
    .kiwi-wrap--statebrowser-drawopen .kiwi-container-statebrowser-messagecount-alert {
        display: none;
    }

    .kiwi-wrap--statebrowser-drawopen .kiwi-container-statebrowser-messagecount-close {
        display: block;
    }

    .kiwi-wrap--statebrowser-drawopen .kiwi-container::after {
        top: 0;
        opacity: 1;
        width: 100%;
        right: 0%;
    }

    .kiwi-header {
        margin-left: 50px;
        margin-right: 50px;
        max-height: 50px;
    }

    .kiwi-container-toggledraw-statebrowser,
    .kiwi-container-toggledraw-sidebar {
        display: block;
    }

    .kiwi-sidebar {
        top: -4px;
    }
}



.kiwi-serverselector-type {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-bottom: 1em;

    input {
        margin: 0;
    }

    label {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
}
.kiwi-serverselector {
    input {
        min-width: 0;
    }
}
.kiwi-serverselector-presets {
    margin-bottom: 1em;
}

.kiwi-serverselector-presets select {
    width: 100%;
}

.kiwi-serverselector-connection {
    display: grid;
    grid-template-columns: max-content auto minmax(0, max-content);
    column-gap: 4px;
    width: 100%;
}

.kiwi-serverselector-connection-proto {
    display: inline-block;
    overflow: hidden;

    select {
        appearance: none;
    }
}

.kiwi-serverselector-connection-address {
    display: inline-block;
}

.kiwi-serverselector-connection-port {
    display: inline-block;

    input {
        // 5 char + padlock + padding
        width: calc(5ch + 1.3em + 20px);
    }

    span {
        position: absolute;
        right: 2px;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.kiwi-serverselector-connection-tls {
    text-align: center;
    cursor: pointer;
    font-size: 1em;
}

.kiwi-serverselector-connection-tls--disabled {
    cursor: default;
}

.kiwi-serverselector-connection-tls i {
    position: relative;
    top: 1px;
    font-size: 1.3em;
}

.kiwi-serverselector-type {
    grid-column: 1 / 4;
}


.kiwi-settings-advanced .u-form {
    input,
    input[type='checkbox'] {
        margin: 0;
    }
}

.kiwi-settings-advanced-notice {
    text-align: center;
    padding: 10px;
    margin: 5px 0 15px 0;
    font-weight: 900;
}

.kiwi-settings-advanced-filter {
    position: relative;
    display: inline-block;

    width: min(50%, 300px);

    input {
        width: 100%;
    }

    input::-ms-clear {
        display: none;
    }

    .fa {
        position: absolute;
        top: 8px;
        right: 10px;
        z-index: 10;
        cursor: default;

        &.fa-times {
            cursor: pointer;
        }
    }
}

.kiwi-settings-advanced-empty {
    width: 100%;
    margin: 50px 0 30px 0;
    text-align: center;
    font-weight: 900;
    font-size: 1em;
}

.kiwi-settings-advanced-table {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-auto-rows: minmax(30px, max-content);

    > div {
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 100%;
        box-sizing: border-box;
    }

    .u-input {
        width: 100%;
        padding: 2px 4px;
    }

    .kiwi-settings-advanced-key {
        padding: 4px 10px;
        overflow: hidden;

        label {
            margin: 0;
            max-width: 100%;
            overflow-wrap: break-word;
        }
    }

    .kiwi-settings-advanced-reset a {
        visibility: hidden;
    }

    a.kiwi-settings-advanced--modified {
        visibility: visible;
    }

    .kiwi-settings-advanced-reset-text {
        margin-right: 4px;
        font-weight: 600;
    }
}

@media screen and (max-width: 1100px) {
    .kiwi-settings-advanced-table {
        grid-template-columns: 1fr max-content;
        grid-auto-flow: row dense;

        .kiwi-settings-advanced-key {
            grid-column: span 2;
        }

        .kiwi-settings-advanced-reset {
            grid-column: 2;
        }

        .kiwi-settings-advanced-value {
            grid-column: 1;
        }

        > div:nth-child(3n+1) {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        > div:nth-child(3n+2),
        > div:nth-child(3n+3) {
            padding-bottom: 8px;
        }
    }
}

@media screen and (max-width: 769px) {
    .kiwi-settings-advanced-filter {
        width: 100%;
    }

    .kiwi-settings-advanced-table {
        > div:nth-child(3n+2) {
            padding-left: 4px;
            padding-right: 8px;
        }

        > div:nth-child(3n+3) {
            padding-right: 4px;
        }

        .kiwi-settings-advanced-reset-text {
            display: none;
        }

        .kiwi-settings-advanced-reset a {
            padding: 0 4px;
        }
    }
}
